import React from "react";
import { useTranslation } from "react-i18next";

const Article = (props) => {
  const { t } = useTranslation();

  return (
    <div className="article-view">
      <div className="article-backgruond"></div>
      <div className="article-wrap">
        <div className="article-content">
          <div className="article-close" onClick={props.hideWindow}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
          </div>
          <div className="article-container">
            <div className="row">
              <div className="col article-img">
                <img src={props.details.img} alt={props.details.name}/>
              </div>
              <div className="col article-info">
                <p>Art.Num: <span>{props.details.public_id}</span></p>
                <hr></hr>
                <h3>{props.details.name}</h3>
                {/* <h4>Article Subtitle</h4> */}
                <p className="article-description">{props.details.description} {props.details.diameter ? ("ø" + props.details.diameter + props.details.diameter_unit) : null}{props.details.size ? (", " + props.details.size + props.details.size_unit) : null}</p>
                {props.priceVisible ?
                  <p className="article-price">{t("product-page.article-price")} {props.details.display_price} {props.details.price_unit}</p>
                  : null
                }
              </div>
            </div>
          </div>
          <div className="article-ok">
            <button className='btn-style' onClick={props.hideWindow}>OK</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;