import React from "react";

const ConfirmPopup = ({ message, yesNo, clickCancel, clickConfirm }) => {
    return (
        <div className='popup-view'>
          <div className='popup-bckgr'></div>
          <div className="popup-wrap" >
            <div className='popup-container'>
              <p>{message}</p>
              <button className='btn-style green' onClick={clickConfirm}>{yesNo.yes}</button>
              <button className='btn-style red' onClick={clickCancel}>{yesNo.no}</button>
            </div>
          </div>
        </div>
    )
}

export default ConfirmPopup;