import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import API from './../../API.js';
import { useHistory, useLocation } from 'react-router-dom';
import Article from './../../components/Article';
import Popup from './../../components/ActionPopup';
import { LangContext } from "./../../contexts/LangContext";
import ProductWithPosition from "./ProductWithPosition";
import Product from "./Product";
import PoyaProduct from "./PoyaProduct";

const BoneProduct = ({ errorNetwork }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { lang } = useContext(LangContext);

  const productName = location.hash.substr(1)

  const [elements, setElements] = useState({
    main: [],
    "va_2.5": {sizes: []},
    "va_3.5": {sizes: []},
    "cortical_2.5": {sizes: []},
    "cortical_3.5": {sizes: []},
    instruments: {parts: []}
  });
  const [orderCompleted, setOrder] = useState(false);
  const [orderValues, setOrderValues] = useState({});
  const [wrnMsg, setWrnMsg] = useState("");
  const [articleWindow, setArticleWindow] = useState(false);
  const [popupVisible, setPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({
    msg: "",
    mark: ""
  });
  const [articleDetails, setArticleDetails] = useState([]);
  const [productID, setProductID] = useState("");
  const [orderType, setOrderType] = useState("");
  const [storageDetails, setStorageDetails] = useState({
    lot_number: "",
    patient_id: ""
  });
  const [isProductAvailable, setIsProductAvailable] = useState(false)

  useEffect(() => {
    const getProduct = () => {
      API.getSingleProduct(productName, lang)
      .then(res => {
        setElements(res.data)
        setOrderType(res.data.order_type)
        setIsProductAvailable(res.data.can_order)
        setProductID(res.data.main[0].product_id)
        setArticleWindow(false)
        setWrnMsg("")
      })
      .catch(error => {
        console.log(error);
        errorNetwork()
      })
    }
    getProduct()
  }, [lang, productName, errorNetwork]);

  useEffect(() => {
    if(popupVisible || articleWindow) {
      setTimeout(() => {
        (document.querySelector("html").style.overflow = "hidden")
      }, 400)
    } else {
      (document.querySelector("html").style.overflow = "auto");
    }
  }, [articleWindow, popupVisible]);

  useEffect(() => {
    const close = (e) => {
      if(!articleWindow) {
        return
      }
      e.preventDefault()
      if(e.keyCode === 27  || e.keyCode === 13){
        setArticleWindow(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [articleWindow])

  const handleorderValues = (e) => {
    if(e.target.value === "0" || e.target.value === "") {
        let reducedObj = {...orderValues}
        delete reducedObj[e.target.id]
        setOrderValues(reducedObj);
    } else {
        setOrderValues({
          ...orderValues,
          [e.target.id]: e.target.value,
        });
    }
  };

  const handleStorageValues = (e) => {
    setStorageDetails({
      ...storageDetails,
      [e.target.id]: e.target.value
    })
  }

  const cancelOrder = () => {
    if (orderCompleted) {
      setOrder(false)
    } else {
      history.replace("")
    }
  }

  const setArticleDetailsHandler = (article) => {
    setArticleDetails(article)
    setArticleWindow(true)
  }

  const handleOrder = () => {
    if (Object.keys(orderValues).length === 0) {
      setWrnMsg(t("product-page.select-quantity-msg"))
      return
    }

    if(orderType === "storage") {
      if(storageDetails.lot_number && storageDetails.lot_number.length < 6) {
        setWrnMsg(t("product-page.lot-num-warning-msg"))
        return
      }
    }

    if(!orderCompleted) {
      setOrder(true)
      setWrnMsg ("")
    } else {
      const orderSubmit = []
      for (let key in orderValues) {
        orderSubmit.push({
          product_id: productID,
          part_id: parseInt(key),
          amount: parseInt(orderValues[key])
        })
      }
      const sendOrder = {
        order: orderSubmit,
      }
      if (storageDetails.lot_number || storageDetails.patient_id) {
        Object.assign(sendOrder, storageDetails);
      }
      API.placeOrder(sendOrder, lang)
      .then(res => {
        setPopupContent({msg: t("product-page.success-order-msg"), mark: "successfull"})
        setPopup(true)
      })
      .catch(error => {
          console.log(error);
          setPopupContent({msg: t("product-page.failed-order-msg"), mark: "error"})
          setPopup(true)
      })
    }
  }

  const checkOrder = () => {
    setPopup(false)
    if(popupContent.mark === "successfull") {
      history.push("/orders")
    }
  }

  return (
    <div className="salgina">
      <div className="container">
        {productName === "Salgina" ? <h1><span>{productName}</span> {t("product-page.product-description-salgina")}
          <br/>
          <span>Dalvazza</span> {t("product-page.product-description-dalvazza")}
        </h1> : null}
        {productName === "Cascella" ? <h1><span>{productName}</span> {t("product-page.product-description-cascella")}</h1> : null}
        {productName === "Tamina" ? <h1><span>Tamina &amp; Tamina TF <br/></span> {t("product-page.product-description-tamina")}</h1> : null}
        {productName === "Trift" ? <h1><span>{productName}</span> {t("product-page.product-description-trift")}</h1> : null}
        {productName === "Poya" ? <h1><span>{productName}-L</span> {t("product-page.product-description-poya1")}
          <br/>
          <span>{productName}-P</span> {t("product-page.product-description-poya2")}
        </h1> : null}
        {productName === "Leporello" ? <h1><span>{productName}</span> {t("product-page.product-description-leporello")}</h1> : null}
       {orderType === "storage" ? 
          <div className="salg-row">
            <div className ="special-details-container">
              <p>{t("product-page.storage-details.p")}</p>
              <div className="storage-inputs">
                <div className="input-wrap">
                  <label>{t("product-page.storage-details.input-label-1")} </label>
                  <input id="patient_id"  type="text" className={`input-num ${orderCompleted ? "input-disabled" : ""}`} onChange={handleStorageValues} disabled={orderCompleted}  defaultValue=""/>
                </div>
                <div className="input-wrap">
                  <label>{t("product-page.storage-details.input-label-2")} </label>
                  <input id="lot_number"  type="text" maxLength={6} className={`input-num ${orderCompleted ? "input-disabled" : ""}`} onChange={handleStorageValues} disabled={orderCompleted}  defaultValue=""/>
                </div>
              </div>
            </div>
          </div> : null
        }
        {(productName !== "Trift" && productName !== "Poya")  && <ProductWithPosition 
            elements={elements} 
            productName={productName} 
            orderCompleted={orderCompleted}
            orderValues={e => handleorderValues(e)}
            setArticleDetails={el => setArticleDetailsHandler(el)}
        />}
        {productName === "Trift" && <Product
            elements={elements} 
            productName={productName} 
            orderCompleted={orderCompleted}
            orderValues={e => handleorderValues(e)}
            setArticleDetails={el => setArticleDetailsHandler(el)}
        />}
          {productName === "Poya" && <PoyaProduct
            elements={elements} 
            productName={productName} 
            orderCompleted={orderCompleted}
            orderValues={e => handleorderValues(e)}
            setArticleDetails={el => setArticleDetailsHandler(el)}
        />}
        <div className="salg-row">
          { (elements["va_2.5"] || elements["va_3.5"]) && <div className="col">
            {elements["va_2.5"] && <div style={{padding: 0}}>
              <h2>{elements["va_2.5"].name} 2.5mm</h2>
              <hr></hr>
              <div className="row">
                <div  className="image-warp schrauben">
                  {elements["va_2.5"].img && <img src={elements["va_2.5"].img} alt="Product VA"></img>}
                </div>
              </div>
              {elements["va_2.5"].sizes.map((size, index) => {
                return (
                  <div key={index} className="input-wrap">
                    <label >
                      <button onClick={() => setArticleDetailsHandler(size)}>{size.public_id}</button>
                      <span>{"ø" + size.diameter + size.diameter_unit} {"L" + size.size + size.size_unit}</span>
                    </label>
                    <input id={size.id} type="number" min="0" max="10" className={`input-num ${orderCompleted ? "input-disabled" : ""}`} disabled={orderCompleted} onChange={handleorderValues} defaultValue="0"/>
                  </div>
                )
              })}
            </div>}
            {elements["va_3.5"] && <div>
              <h2>{elements["va_3.5"].name} 3.5mm</h2>
              <hr></hr>
              <div className="row">
                <div  className="image-warp schrauben">
                  {elements["va_3.5"].img && <img src={elements["va_3.5"].img} alt="Product VA"></img>}
                </div>
              </div>
              {elements["va_3.5"].sizes.map((size, index) => {
                return (
                  <div key={index} className="input-wrap">
                    <label >
                      <button onClick={() => setArticleDetailsHandler(size)}>{size.public_id}</button>
                      <span>{"ø" + size.diameter + size.diameter_unit} {"L" + size.size + size.size_unit}</span>
                    </label>
                    <input id={size.id} type="number" min="0" max="10" className={`input-num ${orderCompleted ? "input-disabled" : ""}`} disabled={orderCompleted} onChange={handleorderValues} defaultValue="0"/>
                  </div>
                )
              })}
            </div>}
          </div>}
          {(elements["cortical_3.5"] || elements["cortical_2.5"]) && 
            <div className={`col ${productName==="Trift" ? "trift-cortex" : ""}`}>
                {elements["cortical_3.5"] && <div>
                <h2>{elements["cortical_3.5"].name} 3.5mm</h2>
                <hr></hr>
                <div className="row">
                    <div  className="image-warp schrauben">
                    {elements["cortical_3.5"].img && <img src={elements["cortical_3.5"].img} alt="Product KO"></img>}
                    </div>
                </div>
                {elements["cortical_3.5"].sizes.map((size, index) => {
                    return (
                    <div key={index} className="input-wrap">
                        <label >
                        <button onClick={() => setArticleDetailsHandler(size)}>{size.public_id}</button>
                        <span>{"ø" + size.diameter + size.diameter_unit} {"L" + size.size + size.size_unit}</span>
                        </label>
                        <input id={size.id} type="number" min="0" max="10" className={`input-num ${orderCompleted ? "input-disabled" : ""}`} disabled={orderCompleted}  onChange={handleorderValues} defaultValue="0"/>
                    </div>
                    )
                })}
            </div>}
            {elements["cortical_2.5"] && <div>
              <h2>{elements["cortical_2.5"].name} 2.5mm</h2>
              <hr></hr>
              <div className="row">
                <div  className="image-warp schrauben">
                  {elements["cortical_2.5"].img && <img src={elements["cortical_2.5"].img} alt="Product KO"></img>}
                </div>
              </div>
              {elements["cortical_2.5"].sizes.map((size, index) => {
                return (
                  <div key={index} className="input-wrap">
                    <label >
                      <button onClick={() => setArticleDetailsHandler(size)}>{size.public_id}</button>
                      <span>{"ø" + size.diameter + size.diameter_unit} {"L" + size.size + size.size_unit}</span>
                    </label>
                    <input id={size.id} type="number" min="0" max="10" className={`input-num ${orderCompleted ? "input-disabled" : ""}`} disabled={orderCompleted}  onChange={handleorderValues} defaultValue="0"/>
                  </div>
                )
              })}
            </div>}
          </div>}
        </div>
        <div className="salg-row">
          {elements.instruments.name && <div className="col fullwidth">
            <h2>{elements.instruments.name}</h2>
            <hr></hr>
            {elements.instruments.parts.map((part, index) => {
              return (
                <div key={index} className="input-wrap">
                  <label >
                    <button onClick={() => setArticleDetailsHandler(part)}>{part.public_id}</button>
                    <span className="part-name">{part.name} {part.diameter ? ("ø" + part.diameter + part.diameter_unit) : null}{part.size ? (", " + part.size + part.size_unit) : null}</span>
                  </label>
                  <input id={part.id} type="number" min="0" max="10" className={`input-num ${orderCompleted ? "input-disabled" : ""}`} disabled={orderCompleted} onChange={handleorderValues} defaultValue="0"/>
                </div>
              )
            })}
          </div>}
        </div>
        <div className="salg-row">
          <span className='warning_msg'> <span className={wrnMsg ? "error" : ""}></span> {wrnMsg}</span>
        </div>
        <div className="order-btns-wrap">
          {isProductAvailable ? <React.Fragment>
            <button className="btn-style red" onClick={cancelOrder}>{!orderCompleted ? t("product-page.cancel") : t("product-page.back")}</button>
            <button className="btn-style green" onClick={handleOrder}>{!orderCompleted ? t("product-page.finish") : t("product-page.order")}</button>
          </React.Fragment>
         : 
          <React.Fragment>
            <button className="btn-style" onClick={cancelOrder} style={{margin: "0 10px"}}>{t("product-page.back")}</button>
            <button className="btn-style">
              <a href={`${API.siteUrl}/contact?lang=${lang}#contactForm`} target="_blank" 
                rel="noopener noreferrer" style={{color: "#333333"}}>{t("products.contact")}</a>
            </button>
          </React.Fragment>}
         </div>
      </div>
      {articleWindow ? <Article details={articleDetails} hideWindow={() => setArticleWindow(false)} priceVisible={isProductAvailable}/> : null}
      {popupVisible ? <Popup click={checkOrder} message={popupContent.msg} mark={popupContent.mark}/> : null}
    </div>
  );
};

export default BoneProduct;