export default {
  translations: {
    "header": {
      "products": "Produits",
      "orders": "Orders"
    },
    "products": {
      "salgina-name": "Salgina",
      "salgina-description": "The SALGINA® 2.5mm Volar Distal Radius Plate was developed together with renowned Swiss surgeons to provide a high standard in fracture care.",
      "cascella-name": "Cascella",
      "trift-name": "Trift",
      "tamina-name": "Tamina",
      "cascella-description": "",
      "trift-description": "",
      "tamina-description": "",
      "available": "Déverrouillé",
      "locked": "Non déverrouillé",
      "order": "Commande",
      "catalog": "Catalogue",
      "coming-soon": "Prochainement",
      "website": "Site Internet",
      "contact": "Contact"
    },
    "product-page": {
      "product-description-salgina": "2.5mm Système Radius Distal Palmaire",
      "product-description-cascella": "3.5mm Système Clavicule Supérieure",
      "product-description-tamina": "3.5mm Système Humérus Proximal",
      "product-description-trift": "3.5mm Plaque Tiers de Tube",
      "product-description-dalvazza": "2.5mm Distal Ulna System",
      "product-description-poya1": "3.5mm Système Tibia Proximal Latéral",
      "product-description-poya2": "3.5mm Système Tibia Postérieur Médial",
      "product-description-leporello": "3.5mm Plaque Olécrane",
      "left": "Gauche",
      "right": "Droite",
      "select-quantity-msg": "Veuillez sélectionner la quantité que vous souhaitez commander.",
      "cancel": "Avorter",
      "back": "Arrière",
      "finish": "Verrouiller",
      "order": "Commander",
      "success-order-msg": "Nous vous remercions de votre commande.",
      "failed-order-msg": "La commande a échoué. Désolé pour le dérangement. Veuillez réessayer ou contactez-nous directement.",
      "storage-details": {
        "p": "Veuillez saisir le numéro de cas ou l'ID patient et le numéro de lot de la plaque utilisée en dernier:",
        "input-label-1": "Numéro de cas / ID patient:",
        "input-label-2": "Numéro de lot:"
      },
      "storage-warning-msg": "Veuillez ajouter les détails de stockage.",
      "lot-num-warning-msg": "Le numéro de lot doit comporter 6 chiffres.",
      "article-price": "Prix ​​de l'article:"
    },
    "orders": {
      "date": "Date",
      "product": 'Produit',
      "order-num": "Numéro de commande",
      "details": "Détails",
      "pdf": "PDF",
      "view": "Vue",
      "download": "Télécharger",
      "no-orders": "Vous n'avez pas encore de commandes.",
      "responsible-person": "Responsable",
      "value": "Valeur",
      "no-results": "Il n'y a pas de résultats pour cette recherche.",
      "search-filters": "Filtres de recherche",
      "last-name": "Nom de famille:",
      "from": "De:",
      "to": "À:",
      "search": "Chercher",
      "all-products": "Tous les produits",
      "product2": 'Produit:',
      "date2": "Date:",
    },
    "order-details": {
      "number": "Pos.",
      "article": "Article",
      "description": "Description",
      "amount": "Quantité",
      "price": "Prix",
      "total": "Total",
      "total-2": "Total sans TVA:",
    },
    "log-out": {
      "logout-question": "Êtes-vous sûr de vouloir quitter la boutique Bonebridge?",
      "logout-yes": "Oui",
      "logout-no": "Non"
    },
    "error-msg-network": "Un problème est survenu."
  }
}