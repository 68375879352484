import React, { createContext, useReducer, useEffect } from "react";
import { langReducer } from "../reducers/langReducer";
import i18n from "./../i18n";

export const LangContext = createContext();

const LangContextProvider = ({ children }) => {
  const [lang, dispatch] = useReducer(langReducer, "de", () => {
    const localData = localStorage.getItem("lang");
    return localData ? localData : "de";
  });
  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }, [lang]);
  return (
    <LangContext.Provider value={{ lang, dispatch }}>
      {children}
    </LangContext.Provider>
  );
};

export default LangContextProvider;
