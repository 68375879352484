import React from "react";

const Popup = (props) => {
    return (
        <div className='popup-view'>
          <div className='popup-bckgr'></div>
          <div className="popup-wrap" >
            <div className='popup-container'>
              <span className={props.mark}></span> 
              <p>{props.message}</p>
              <button className='btn-style' onClick={props.click}>OK</button>
            </div>
          </div>
        </div>
    )
}

export default Popup;