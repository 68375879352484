import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en";
import de from "./translations/de";
import fr from "./translations/fr";

i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("lang") || "de",
    // we init with resources
    resources: {
      en,
      de,
      fr
    },
    fallbackLng: "de",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
