import React from 'react';

const Spinner = () => {
  return (
    <div className='spinner_container'>
      <div className='spinner_wrap'> 
        <div className="lds-css ng-scope">
          <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spinner;