import React from "react";

import { useTranslation } from "react-i18next";

export default function ProductWithPosition ({elements, productName, orderCompleted, orderValues, setArticleDetails}) {
  const { t } = useTranslation();

    return (
        elements.main && <div className="salg-row">
            <div className="col">
                <h2>
                    <span className="poya-title">POYA-L {t("product-page.left")}</span>
                    <span className="poya-title">POYA-L {t("product-page.right")}</span>
                </h2> 
                <hr></hr>
                <div className="row">
                {elements.main.map((el, index)=>{
                    return (
                        (index === 0 || index === 1) ?  <div key={index} className="col">
                        <div  className="image-warp">
                            <img src={el.img} className={`${productName}-img`} alt="Product Left"></img>
                        </div>
                        <div  className="input-wrap inrows">
                            <label >
                                <button 
                                    onClick={() => setArticleDetails(el)}
                                >
                                {el.public_id}
                                </button>
                            <span className={`part-name ${productName}`}>POYA-L</span>
                            </label>
                            <input id={el.id} type="number" min="0" max="10" 
                                className={`input-num ${orderCompleted ? "input-disabled" : ""}`} 
                                disabled={orderCompleted} 
                                onChange={e => orderValues(e)}  
                                defaultValue="0"
                            />
                        </div>
                        </div> : null
                    )
                    })}
                </div>
            </div>
            <div className="col">
                <h2>
                    <span className="poya-title">POYA-P {t("product-page.left")}</span>
                    <span className="poya-title">POYA-P {t("product-page.right")}</span>
                </h2>
                <hr></hr>
                <div className="row">
                {elements.main.map((el, index)=>{
                    return (
                    (index === 2 || index === 3) ? <div key={index}  className="col">
                        <div  className="image-warp">
                            <img src={el.img} className={`${productName}-img`} alt="Product Rigth"></img>
                        </div>
                        <div className="input-wrap inrows">
                        <label >
                            <button 
                                onClick={() => setArticleDetails(el)}
                            >{el.public_id}</button>
                            <span className={`part-name ${productName}`}>POYA-P</span>
                        </label>
                        <input id={el.id} type="number" min="0" max="10" 
                                className={`input-num ${orderCompleted ? "input-disabled" : ""}`} 
                                disabled={orderCompleted}  
                                onChange={e => orderValues(e)} 
                                defaultValue="0"
                            />
                        </div>
                    </div> : null
                    )
                })}
                </div>
            </div>
        </div>
    )
}