export default {
  translations: {
    "header": {
      "products": "Produkte",
      "orders": "Aufträge"
    },
    "products": {
      "salgina-name": "Salgina",
      "salgina-description": "Die SALGINA® 2.5mm Volare Distale Radiusplatte wurde zusammen mit renommierten Schweizer Chirurgen entwickelt, um den höchstmöglichen Standard in der Frakturversorgung zu gewährleisten.",
      "cascella-name": "Cascella",
      "trift-name": "Trift",
      "tamina-name": "Tamina",
      "cascella-description": "",
      "trift-description": "",
      "tamina-description": "",
      "available": "Freigeschaltet",
      "locked": "Nicht freigeschaltet",
      "order": "Bestellen",
      "catalog": "Katalog",
      "coming-soon": "Demnächst",
      "website": "Webseite ",
      "contact": "Kontakt"
    },
    "product-page": {
      "product-description-salgina": "2.5mm Volares Distales Radiussystem",
      "product-description-cascella": "3.5mm Superiores Klavikula System",
      "product-description-tamina": "3.5mm Proximales Humerus System",
      "product-description-trift": "3.5mm Drittelrohrplatte",
      "product-description-dalvazza": "2.5mm Distales Ulnasystem",
      "product-description-poya1": "3.5mm  Lateral Proximales Tibiasystem",
      "product-description-poya2": "3.5mm Posteromediales Tibiasystem",
      "product-description-leporello": "3.5mm Olekranonplatte",
      "left": "Links",
      "right": "Rechts",
      "select-quantity-msg": "Bitte wählen Sie die Menge aus, die Sie bestellen möchten.",
      "cancel": "Abbrechen",
      "back": "Zurück",
      "finish": "Abschliessen",
      "order": "Bestellen",
      "success-order-msg": "Vielen Dank für Ihren Auftrag.",
      "failed-order-msg": "Bestellung fehlgeschlagen. Entschuldigung für die Unannehmlichkeiten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns direkt.",
      "storage-details": {
        "p": "Bitte geben Sie die Fallnummer bzw. Patienten-ID sowie die Lot-Nummer der zuletzt verwendeten Platte an:",
        "input-label-1": "Fallnummer / Patienten-ID:",
        "input-label-2": "Lot-Nummer:"
      },
      "storage-warning-msg": "Bitte fügen Sie die Lot-Nummer bzw. PID hinzu.",
      "lot-num-warning-msg": "Lot-Nummer muss 6-stellig sein.",
      "article-price": "Artikelpreis:"
    },
    "orders": {
      "date": "Datum",
      "product": 'Produkt',
      "order-num": "Bestellnummer",
      "details": "Einzelheiten",
      "pdf": "PDF",
      "view": "Ansicht",
      "download": "Herunterladen",
      "no-orders": "Sie haben noch keine Bestellungen.",
      "responsible-person": "Verantwortlich",
      "value": "Warenwert",
      "no-results": "Es gibt keine Ergebnisse für diese Suche.",
      "search-filters": "Filter suchen",
      "last-name": "Nachname:",
      "from": "Von:",
      "to": "Bis:",
      "search": "Suche",
      "all-products": "Alle Produkte",
      "product2": 'Produkt:',
      "date2": "Datum:",
    },
    "order-details": {
      "number": "Pos.",
      "article": "Artikel",
      "description": "Bezeichnung",
      "amount": "Liefermenge ME",
      "price": "Preis",
      "total": "Total",
      "total-2": "Total ohne Mwst:",
    },
    "log-out": {
      "logout-question": "Sind Sie sicher, dass Sie den Bonebridge-Shop verlassen möchten?",
      "logout-yes": "Ja",
      "logout-no": "Nein"
    },
    "error-msg-network": "Etwas ist schief gelaufen."
  }
}