export default {
  translations: {
    "header": {
      "products": "Products",
      "orders": "Orders"
    },
    "products": {
      "salgina-name": "Salgina",
      "salgina-description": "The SALGINA® 2.5mm Volar Distal Radius Plate was developed together with renowned Swiss surgeons to provide a high standard in fracture care.",
      "cascella-name": "Cascella",
      "trift-name": "Trift",
      "tamina-name": "Tamina",
      "cascella-description": "",
      "trift-description": "",
      "tamina-description": "",
      "available": "Available",
      "locked": "Not Available",
      "order": "Order",
      "catalog": "Catalog",
      "coming-soon": "Coming soon",
      "website": "Website",
      "contact": "Contact"
    },
    "product-page": {
      "product-description-salgina": "2.5mm Volar Distal Radius System",
      "product-description-cascella": "3.5mm Superior Clavicle System",
      "product-description-tamina": "3.5mm Proximal Humerus System",
      "product-description-trift": "3.5mm Tubular Plate System",
      "product-description-dalvazza": "2.5mm Distal Ulna System",
      "product-description-poya1": "3.5mm Lateral Proximal Tibia System",
      "product-description-poya2": "3.5mm Posteromedial Tibia System",
      "product-description-leporello": "3.5mm Olecranon Plate",
      "left": "Left",
      "right": "Right",
      "select-quantity-msg": "Please select the quantity you would like to order.",
      "cancel": "Cancel",
      "back": "Back",
      "finish": "Complete",
      "order": "Order",
      "success-order-msg": "Thank you for your order.",
      "failed-order-msg": "Ordering failed. Sorry for the inconvenience. Please try again, or contact us directly.",
      "storage-details": {
        "p": "Please enter the case number or patient ID as well as the lot number of the plate used last:",
        "input-label-1": "Case number / Patient ID:",
        "input-label-2": "Lot number:"
      },
      "storage-warning-msg": "Please add Storage details.",
      "lot-num-warning-msg": "Lot Number must be 6 digists long.",
      "article-price": "Article Price:"
    },
    "orders": {
      "date": "Date",
      "product": 'Product',
      "order-num": "Order Number",
      "details": "Details",
      "pdf": "PDF",
      "view": "View",
      "download": "Download",
      "no-orders": "You don't have any orders yet.",
      "responsible-person": "Responsible",
      "value": "Order value",
      "no-results": "There are no results for this search.",
      "search-filters": "Search Filters",
      "last-name": "Last Name:",
      "from": "From:",
      "to": "To:",
      "search": "Search",
      "all-products": "All Products",
      "product2": 'Product:',
      "date2": "Date:",
    },
    "order-details": {
      "number": "Pos.",
      "article": "Article",
      "description": "Description",
      "amount": "Delivery quantity",
      "price": "Price",
      "total": "Total",
      "total-2": "Total without VAT:",
    },
    "log-out": {
      "logout-question": "Are you sure you want to leave Bonebridge shop?",
      "logout-yes": "Yes",
      "logout-no": "No"
    },
    "error-msg-network": "Something went wrong."
  }
}