import React, { useEffect, useState, useContext } from "react";
import API from './../API.js';
import { LangContext } from "./../contexts/LangContext";
import { useTranslation } from "react-i18next";
import { parsePrice } from "../helpers"

const OrderDetails = ({id, errorNetwork, hideWindow }) => {
  const { t } = useTranslation();
  const { lang } = useContext(LangContext);
  const [order, setOrder] = useState([]);
  const [total_price, setTotalPrice] = useState(1);

  useEffect(() => {
    const getOrderDetails = (lang) => {
      API.getOrderDetails(id, lang)
      .then(res => {
        setOrder(res.data.order)
        let TOTAL = 0;
        for (let index = 0; index < res.data.order.length; index++) {
          TOTAL += res.data.order[index].total_price
        }
        setTotalPrice(parsePrice(TOTAL));
      })
      .catch(error => {
          console.log(error);
          errorNetwork()
      })
    }
    getOrderDetails(lang)
  }, [lang, id, errorNetwork]);

  return (
    <div className="order-details-view">
      <div className="order-details-backgruond"></div>
      <div className="order-details-wrap">
        <div className="order-details-content">
          <div className="order-details-close" onClick={hideWindow}>
            <div className="leftright"></div>
            <div className="rightleft"></div>
          </div>
          <div className="order-details-container">
            {order && <div className="row">
              <div className="col order-table">
                <div className="order-table-header">
                  <div className="order-header-cell small-cell">{t("order-details.number")}</div>
                  <div className="order-header-cell">{t("order-details.article")}</div>
                  <div className="order-header-cell big-cell">{t("order-details.description")}</div>
                  <div className="order-header-cell">{t("order-details.amount")}</div>
                  <div className="order-header-cell" style={{textAlign: "right"}}>{t("order-details.price")}</div>
                  <div className="order-header-cell" style={{textAlign: "right"}}>{t("order-details.total")}</div>
                </div>
                <div className="order-table-body">
                  {order.map((article, index) => {
                    return  <div key={index} className="order-table-row">
                              <div className="order-body-cell small-cell">{index + 1}</div>
                              <div className="order-body-cell">
                                <span>{article.part.public_id}</span>
                              </div>
                              <div className="order-body-cell big-cell">
                                <p>{article.part.description + ""} {article.part.diameter ? `⌀${article.part.diameter}${article.part.diameter_unit}` : null} {article.part.size ? `L${article.part.size}${article.part.size_unit}` : null}</p>
                              </div>
                              <div className="order-body-cell">{article.amount}</div>
                              <div className="order-body-cell"  style={{textAlign: "right"}}>{article.display_price_per_part} {article.price_unit}</div>
                              <div className="order-body-cell" style={{textAlign: "right"}}>
                                <span>{article.display_total_price} {article.price_unit}</span>
                              </div>
                            </div>
                  })}
                </div>
                <div className="order-table-total">
                {t("order-details.total-2")} {total_price} CHF
                </div>
              </div>
            </div>}
          </div>
          <div className="order-details-ok">
            <button className='btn-style' onClick={hideWindow}>OK</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;