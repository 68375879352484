import React from "react";

import { useTranslation } from "react-i18next";

export default function ProductWithPosition ({elements, productName, orderCompleted, orderValues, setArticleDetails}) {
  const { t } = useTranslation();

    return (
        elements.main && <div className="trift">
            <h2>{t("product-page.product-description-trift")}</h2>
            <hr></hr>
            {elements.main.map((el, index)=>{
            return (
                <div key={index} className="trift-row">
                    <div  className="image-warp">
                        <img src={el.img} alt="Product"></img>
                    </div>
                    <div  className="input-wrap">
                        <label >
                            <button 
                                onClick={() => setArticleDetails(el)}
                            >
                            {el.public_id}
                            </button>
                        <span className={`part-name ${productName}`}>{el.name}</span>
                        </label>
                        <input id={el.id} type="number" min="0" max="10" 
                            className={`input-num ${orderCompleted ? "input-disabled" : ""}`} 
                            disabled={orderCompleted} 
                            onChange={e => orderValues(e)}  
                            defaultValue="0"
                        />
                    </div>
                </div>
                )
            })}
        </div>
    )
}