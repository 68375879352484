import axios from "axios";

const siteUrl = process.env.REACT_APP_ENV === 'production' ? 'https://bonebridge.ch' : 'https://wunschbuero.ch';
const shopUrl = process.env.REACT_APP_ENV === 'production' ? 'https://shop.bonebridge.ch' : 'https://shop.wunschbuero.ch';

// intercept each request
axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  if (401 === error.response.status) {
    // try and refresh token
    try {
      await axios.get(`${shopUrl}/api/auth/token/refresh`);
      return axios.request(error.config);
    } catch (error) {
      window.location.replace(`${siteUrl}/order`);
    }
  } else {
    return Promise.reject(error.response.data.error);
  }
});

export default {
  shopUrl,
  siteUrl,
  logOut() {
    return axios.post(`${shopUrl}/api/auth/token/logout`);
  },
  getProducts(lang) {
    return axios.get(`${shopUrl}/api/products?lang=${lang}`);
  },
  getSingleProduct(productName, lang) {
    return axios.get(`${shopUrl}/api/parts?product=${productName}&lang=${lang}`);
  },
  placeOrder(order, lang) {
    return axios.put(`${shopUrl}/api/orders?lang=${lang}`, order)
  },
  getOrders(queryObj) {
    return axios.get(`${shopUrl}/api/orders`, queryObj);
  },
  getOrderDetails(id, lang) {
    return axios.get(`${shopUrl}/api/orders/${id}?lang=${lang}`);
  },
  downloadPDF(id, lang) {
    return axios.get(`${shopUrl}/api/orders/${id}/downloadPdf?lang=${lang}`);
  }
}
