import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Switch, Route, withRouter, useLocation } from "react-router-dom";
import { LangContext } from "./contexts/LangContext";
import Navbar from "./components/Navbar";
import Products from "./views/Products";
import Orders from "./views/Orders";
import Product from "./views/Product";
import ConfirmPopup from "./components/ConfirmationPopup";
import { useTranslation } from "react-i18next";
import API from './API.js';
import Popup from './components/ActionPopup';

function App() {
  const location = useLocation();
  const { dispatch } = useContext(LangContext);
  const { t } = useTranslation();
  const [logOutPopup, setLogOutPopup] = useState(false)
  const [popupVisible, setPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState("");
  const [logoutWithLang, setLogoutWithLang] = useState("");

  const logOutHandler = (lang) => {
    setLogOutPopup(true)
    setLogoutWithLang(lang)
  }

  useEffect(() => {
    if (location.search === "?lang=de" || location.search === "?lang=en" || location.search === "?lang=fr") {
      var lang = location.search.split("?lang=")[1]
      dispatch({ type: "CHANGE_LANG", value: lang });
    }
 }, [location.search, dispatch])

  useEffect(() => {
    logOutPopup
      ? setTimeout(() => {
        (document.querySelector("html").style.overflow = "hidden")
      }, 400)
      : (document.querySelector("html").style.overflow = "auto");
  }, [logOutPopup]);

  const ConfirmLogOut = async () => {
    setLogOutPopup(false)
    try {
      await API.logOut();
      window.location.replace(`${API.siteUrl}/order?lang=${logoutWithLang}`);
    } catch (error) {
      console.log(error)
      setPopup(true)
      setPopupMsg(t("error-msg-network"))
    }
  }

  const CancelLogOut = () => {
    setLogOutPopup(false)
  }

  const closePopup = () => {
    setPopup(false)
  }

  const errorNetworkHandler = useCallback(() => {
    setPopup(true)
    setPopupMsg(t("error-msg-network"))
  }, [t])

  return (
    <div className="App">
      <Navbar logout={logOutHandler}/>
      <Switch>
          <Route exact path="/">
            <Products errorNetwork={errorNetworkHandler}/>
          </Route>
          <Route exact path="/orders">
            <Orders errorNetwork={errorNetworkHandler}/>
          </Route>
          <Route path="/product">
            <Product  errorNetwork={errorNetworkHandler}/>
          </Route>
        </Switch>
        {logOutPopup ? <ConfirmPopup message={t("log-out.logout-question")} yesNo={{ yes: t("log-out.logout-yes"), no: t("log-out.logout-no")}} clickConfirm={ConfirmLogOut} clickCancel={CancelLogOut}/> : null}
        {popupVisible ? <Popup click={closePopup} message={popupMsg} mark="error"/> : null}
    </div>
  );
}

export default withRouter(App);
