import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Logo from "./../assets/images/logo/logo.png";
// import Profile from "./../assets/images/icons/user.png";
import LogOut from "./../assets/images/icons/exit.png";
import { useTranslation } from "react-i18next";
import { LangContext } from "./../contexts/LangContext";
import { FaAngleDown } from "react-icons/fa";
 
const Navbar = (props) => {
  const { t } = useTranslation();
  const { lang, dispatch } = useContext(LangContext);
  const [showLangDrop, setShowLangDrop] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const getLang = (lng) => {
    switch (lng) {
      case "en":
        return "English";
      case "de":
        return "Deutsch";
      case "fr":
        return "Français";
      default:
        return "English";
    }
  };
 
  const changeLanguage = (lng) => {
    dispatch({ type: "CHANGE_LANG", value: lng });
    setShowLangDrop(false);
  };
  const detectClickOutside = () => {
    const langDrop = document.querySelector(".lang-holder");
    const langDropMob = document.querySelector(".lang-holder-mob");
    // const mobieleMenu = document.querySelector(".mobile-menu")
    document.addEventListener("click", (e) => {
      const isClickInsideLang = langDrop.contains(e.target) || langDropMob.contains(e.target);
      if (!isClickInsideLang) {
        setShowLangDrop(false);
        // setShowMenu(false)
      }
    });
  };
  useEffect(() => detectClickOutside);

  const setShowMenuHandler = () => {
    setShowMenu(false)
  }

  return (
    <header className="navbar">
      <div className="container">
        <div className="row">
          <div className="col header-left">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="col header-center">
            <NavLink className="nav-link" exact activeClassName="selected" to="/">
              {t("header.products")}
            </NavLink>
            <NavLink className="nav-link" activeClassName="selected" to="/orders">
                {t("header.orders")}
              </NavLink>
          </div>
          <div className="col header-right">
            <div className="lang-holder nav-link" onClick={() => setShowLangDrop(!showLangDrop)}>
              <p className="drop-value">
                <span>
                  {getLang(lang)}
                </span>
                <FaAngleDown className="fa" />
              </p>
              <div className={`lang-dropdown ${showLangDrop ? "show" : ""}`}>
                {lang !== "en" && (
                  <div onClick={() => changeLanguage("en")}>English</div>
                )}
                {lang !== "de" && (
                  <div onClick={() => changeLanguage("de")}>Deutsch</div>
                )}
                {lang !== "fr" && (
                  <div onClick={() => changeLanguage("fr")}>Français</div>
                )}
              </div>
            </div>
            {/* <Link className="nav-link" to="/">
              <img src={Profile} alt="Profile" title="Profile" className="icons"/>
            </Link> */}
            <button className="nav-link log-out-btn" onClick={() => props.logout(lang)}>
              <img src={LogOut} alt="Log out" title="Log out" className="icons"/>
            </button>
          </div>
          <div className="header-right-mobile">
            <div
              className={`toggle-icon hamburger ${showMenu ? "" : "show"}`}
              onClick={() => setShowMenu(true)}
            >
              <span></span>
            </div>
            <div
              className={`toggle-icon close ${showMenu ? "show" : ""}`}
              onClick={() => setShowMenu(false)}
            >
              <div id="mdiv">
                <div className="mdiv">
                  <div className="md"></div>
                </div>
              </div>
            </div>
          </div>
          <div className={`mobile-menu ${showMenu ? "show" : ""}`}>
            <NavLink name="products" className="nav-link" exact activeClassName="selected" to="/"  onClick={() => setShowMenuHandler()}>
              {t("header.products")}
            </NavLink>
            <NavLink name="orders" className="nav-link" activeClassName="selected" to="/orders" onClick={(e) => setShowMenuHandler()} >
              {t("header.orders")}
            </NavLink>
            <button onClick={() => {
                setShowMenu(false)
                props.logout(lang)
              }} 
              className="nav-link log-out-btn" > Log Out
            </button>
            <div className="lang-holder-mob nav-link" onClick={() => setShowLangDrop(!showLangDrop)}>
              <div onClick={() => changeLanguage("en")}  className={lang === "en" ? "selected-lang" : ""}>EN</div>
              <span>/</span>
              <div onClick={() => changeLanguage("de")}  className={lang === "de" ? "selected-lang" : ""}>DE</div>
              <span>/</span>
              <div onClick={() => changeLanguage("fr")}  className={lang === "fr" ? "selected-lang" : ""}>FR</div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navbar;
