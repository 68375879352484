import React, { useEffect, useState, useContext }  from "react";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from './../API.js';
import { LangContext } from "./../contexts/LangContext";
import Spinner from "./../components/loadingSpinner";

const Products = ({errorNetwork}) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [spinnerVisible, setSpinnerVisibility] = useState(true);
  const { lang } = useContext(LangContext);

  useEffect(() => {
    const getProducts = () => {
      API.getProducts(lang)
      .then(res => {
        if (res && res.data) {
          setProducts(res.data.products)
          setSpinnerVisibility(false)
        }
      })
      .catch(error => {
        console.log(error)
        setSpinnerVisibility(false)
        errorNetwork()
      })
    }
  
    getProducts()
  }, [lang, errorNetwork]);

  return (
    <div className="products-view">
      <div className="container">
        <div className="row">
          {products && products.map((product, index) => {
            return (
              <div key={index} className="col single_product">
                <div className="img-wrap">
                  <img src={product.img} alt={product.name}></img>
                </div>
                <h1>{product.name}{product.name === "Poya" ? "-L" : ""}
                  <br/>
                    {product.name === "Tamina" ? "& Tamina TF" : ""}
                    {product.name === "Salgina" ? "& DALVAZZA" : ""}
                    {product.name === "Poya" ? "& Poya-P" : ""}
                </h1>
                <p>{product.description}</p>
                <span>{product.available ? t("products.available") : t("products.locked")}</span>
                <div className="options-container">
                  <Link to={`product#${product.name}`}>
                    <span>{product.available ? t("products.order") : t("products.catalog")}</span>
                  </Link>
                  <a href={`${API.siteUrl}/${product.name}?lang=${lang}`} target="_blank" rel="noopener noreferrer">{t("products.website")}</a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {spinnerVisible ? <Spinner/> : null}
    </div>
  );
};

export default Products;
