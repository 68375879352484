import React, { useEffect, useState, useContext } from "react";
import API from './../API.js';
import { formatDate } from './../helpers/index';
import OrderDetails from './../components/OrderDetails'
import { useTranslation } from "react-i18next";
import { LangContext } from "./../contexts/LangContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "./../components/loadingSpinner";

const Orders = ({errorNetwork}) => {
  const { t } = useTranslation();
  const { lang } = useContext(LangContext);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [orderWindow, setOrderWindow] = useState(false);
  const [order_id, setOrderID] = useState("");
  const [orderDoc] = useState({});
  const [downloadOrder] = useState(null);
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [productFilter, setProductFilter] = useState("");
  const [userFilter, setUserFilter] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [spinnerVisible, setSpinnerVisibility] = useState(true);

  useEffect(() => {
    API.getOrders(lang)
    .then(res => {
      setOrders(res.data.orders)
      setProducts(res.data.products)
      setSpinnerVisibility(false)
      setResultMessage(t("orders.no-orders"))
      setProductFilter("")
      setDateTo(null)
      setDateFrom(null)
      setUserFilter("")
    })
    .catch(error => {
        console.log(error);
        setSpinnerVisibility(false)
        errorNetwork()
    })
  }, [lang, errorNetwork, t]);

  useEffect(() => {
    orderWindow
      ? setTimeout(() => {
        (document.querySelector("html").style.overflow = "hidden")
      }, 400)
      : (document.querySelector("html").style.overflow = "auto");
  }, [orderWindow]);

  useEffect(() => {
    const close = (e) => {
      if(!orderWindow) {
        return
      }
      e.preventDefault()
      if(e.keyCode === 27  || e.keyCode === 13){
        setOrderWindow(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [orderWindow])

  const getOrders = (queryObj) => {
    API.getOrders(queryObj)
    .then(res => {
      setOrders(res.data.orders)
      setSpinnerVisibility(false)
      if(queryObj) {
        setResultMessage(t("orders.no-results"))
      }
    })
    .catch(error => {
      console.log(error);
      setSpinnerVisibility(false)
      errorNetwork()
    })
  }

  const setOrderDetailsHandler = (id) => {
    setOrderID(id)
    setOrderWindow(true)
  }

  const productFilterHandler = (e) => {
    setProductFilter(e.target.value)
    searchOrdersHandler(e.target.value)
  }

  const userFilterHandler = (e) => {
    setUserFilter(e.target.value)
  }

  const searchOrdersHandler = (product) => {
    let queryObj =  {
      params: {
        orderProduct: product
      }
    }
    if(userFilter) {
      queryObj.params["orderUser"] = userFilter
    }
    if(dateFrom) {
      queryObj.params["fromDate"] =dateFrom.toISOString()
    }
    if(dateTo) {
      queryObj.params["toDate"] =dateTo.toISOString()
    }
    getOrders(queryObj)
  }

  const errorNetworHere = () => {
    errorNetwork()
   }

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      searchOrdersHandler(productFilter)
    }
  }

  return (
    <div className="orders-view">
      <div className="container">
        <a id="report_link" href={downloadOrder} download={"order:" + orderDoc.id + "/" + formatDate(new Date(orderDoc.created_at)).split(",").pop() + '.pdf'}>this is a report link</a>
        <div className="search-wrapper">
          <div className="row">
            <div className="col filter">
              <label>{t("orders.product2")}</label>
              <select className='selectable-dropown' value={productFilter} onChange={(e) => productFilterHandler(e)}>
                <option value="">{t("orders.all-products")}</option>
                {products && products.map((product, i) => {
                  return (
                    <option key={i} value={product.id}>{product.name.toUpperCase()}</option>
                  )
                })}
              </select>
            </div>
            <div className="col filter">
              <label>{t("orders.last-name")}</label>
              <input className='search-input' type="text" value={userFilter}
                onChange={(e) => userFilterHandler(e)}
                onKeyPress={onKeyPressHandler}
                />
            </div>
            <div className="col filter">
              <label>{t("orders.date2")}</label>
              <div className="dates-container">
                <DatePicker
                  className='search-input'
                  dateFormat="dd/MM/yyyy"
                  name="date"
                  selected={dateFrom}
                  isClearable
                  placeholderText={t("orders.from")}
                  onChange={date => setDateFrom(date)}
                  autoComplete='off'
                />
                <span className="separator">-</span>
                <DatePicker
                  className='search-input'
                  dateFormat="dd/MM/yyyy"
                  name="date"
                  selected={dateTo}
                  isClearable
                  placeholderText={t("orders.to")}
                  onChange={date => setDateTo(date)}
                  autoComplete='off'
                />
              </div>
            </div>
            <div className="col button-search">
              <button className="btn-style" onClick={() => searchOrdersHandler(productFilter)}>{t("orders.search")}</button>
            </div>
          </div>
        </div>
        <div className="row">
        {orders.length !== 0 ?
          <table className="orders-table">
            <thead>
              <tr>
                <th><div>{t("orders.date")}</div></th>
                <th className="small-screen-remove"><div>{t("orders.responsible-person")}</div></th>
                <th><div>{t("orders.product")}</div></th>
                <th className="small-screen-remove"><div>{t("orders.value")}</div></th>
                <th><div>{t("orders.details")}</div></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                return (
                  <tr key={index}>
                    <td>{order.created_at && formatDate(new Date(order.created_at)).split(",").pop()}</td>
                    <td className="small-screen-remove">{order.user_name && order.user_name}</td>
                    <td className="product-name">{order.product_name && order.product_name}</td>
                    <td className="small-screen-remove">{order.total_price && order.total_price}</td>
                    <td>
                      <button onClick={() => setOrderDetailsHandler(order.id)} className="btn-as-link">{t("orders.view")}</button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table> : resultMessage}
        </div>
      </div>
      {orderWindow ? <OrderDetails id={order_id} errorNetwork={errorNetworHere} hideWindow={() => setOrderWindow(false)}/> : null}
      {spinnerVisible ? <Spinner/> : null}
    </div>
  );
};

export default Orders;