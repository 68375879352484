import React from "react";

import { useTranslation } from "react-i18next";

export default function ProductWithPosition ({elements, productName, orderCompleted, orderValues, setArticleDetails}) {
  const { t } = useTranslation();

    return (
        elements.main && <div className="salg-row">
            <div className="col">
                <h2>{`${productName === "Salgina" ? productName : ""}  ${t("product-page.left")}`}</h2>
                <hr></hr>
                <div className="row">
                {elements.main.map((el, index)=>{
                    return (
                        el.position === "left" ?  <div key={index} className="col product">
                        <div  className="image-warp">
                            <img src={el.img} className={`${productName}-img`} alt="Product Left"></img>
                        </div>
                        <div  className="input-wrap inrows">
                            <label >
                                <button 
                                    onClick={() => setArticleDetails(el)}
                                >
                                {el.public_id}
                                </button>
                            <span className={`part-name ${productName}`}>{el.name}</span>
                            </label>
                            <input id={el.id} type="number" min="0" max="10" 
                                className={`input-num ${orderCompleted ? "input-disabled" : ""}`} 
                                disabled={orderCompleted} 
                                onChange={e => orderValues(e)}  
                                defaultValue="0"
                            />
                        </div>
                        </div> : null
                    )
                    })}
                </div>
            </div>
            <div className="col">
                <h2>{`${productName === "Salgina" ? productName : ""}  ${t("product-page.right")}`}</h2>
                <hr></hr>
                <div className="row">
                {elements.main.map((el, index)=>{
                    return (
                    el.position === "right" ? <div key={index}  className="col product">
                        <div  className="image-warp">
                            <img src={el.img} className={`${productName}-img`} alt="Product Rigth"></img>
                        </div>
                        <div className="input-wrap inrows">
                        <label >
                            <button 
                                onClick={() => setArticleDetails(el)}
                            >{el.public_id}</button>
                            <span className={`part-name ${productName}`}>{el.name}</span>
                        </label>
                        <input id={el.id} type="number" min="0" max="10" 
                                className={`input-num ${orderCompleted ? "input-disabled" : ""}`} 
                                disabled={orderCompleted}  
                                onChange={e => orderValues(e)} 
                                defaultValue="0"
                            />
                        </div>
                    </div> : null
                    )
                })}
                </div>
            </div>
            {productName === "Salgina" && <div className="col Dalvazza">
                <h2>Dalvazza</h2>
                <hr></hr>
                <div className="row">
                {elements.main.map((el, index)=>{
                    return (
                    el.position === null ? <div key={index} className="col product">
                        <div  className="image-warp">
                            <img src={el.img} className="Dalvazza-img" alt="Product"></img>
                        </div>
                        <div className="input-wrap inrows">
                        <label >
                            <button 
                                onClick={() => setArticleDetails(el)}
                            >{el.public_id}</button>
                            {/* <span className={`part-name ${productName}`}>{el.name}</span> */}
                        </label>
                        <input id={el.id} type="number" min="0" max="10" 
                                className={`input-num ${orderCompleted ? "input-disabled" : ""}`} 
                                disabled={orderCompleted}  
                                onChange={e => orderValues(e)} 
                                defaultValue="0"
                            />
                        </div>
                    </div> : null
                    )
                })}
                </div>
            </div>}
        </div>
    )
}