function formatDate(date) {

  var day = date.getDate();
  var monthIndex = date.getMonth() + 1 ;
  var year = date.getFullYear();
  var hours = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();

  hours = hours < 10 ? '0'+hours : hours;
  min = min < 10 ? '0'+min : min;
  sec = sec < 10 ? '0'+sec : sec;

  return hours + ':' + min + ':' + sec + ', ' + day + '.' + monthIndex + '.' + year;
}

function parsePrice(price) {
  let displayPrice = price.toLocaleString("de-CH");
  if (!displayPrice.includes('.')) displayPrice += '.00';
  return displayPrice;
}

export {
    formatDate,
    parsePrice
}